import React, { Component } from "react";

import jsQR from "jsqr";
import { getLocation } from './utils'
import { generateQR } from './Qr';

import queryString from 'query-string';
import { Link } from "react-router-dom";

const width = 640;
const height = 425;

const styles = {
  button: {
    //display: "inline-block",
    //height: "30px",
    //width: "30px",
    //background: "#000",
    cursor: "pointer"
  },
  input: {
    position: "absolute",
    overflow: "hidden",
    width: "1px",
    height: "1px",
    opacity: 0
  }
};

function Item({ data, qrData }) {
  // const title = data.
  const { query } = queryString.parseUrl(data);
  const { host } = getLocation(data);
  const valid = host === 'scan.plings.io';
  console.log(query);



  return (
    <div className="uk-card uk-card-secondary uk-width-1-2@m">
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle" data-uk-grid>
          <div className="uk-width-auto ">
            <img className="uk-border-circle" width="100" height="100" src={qrData} alt='' />
            <span uk-icon="icon: pencil"></span>
          </div>
          <div class="uk-card uk-card-body">
            {!valid &&
              <div className="uk-alert-danger" data-uk-alert>
                untrusted domain
              </div>
            }
            <h3 className="uk-card-title uk-margin-remove-bottom">
              <Link to={`/pling/${query.pling}`}>{query.pling}</Link>
            </h3>
            <p className="uk-text-meta uk-margin-remove-top"><time dateTime="2016-04-01T19:00">April 01, 2016</time></p>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
      </div>
      <div className="uk-card-footer">
        <a href={data} className="uk-button uk-button-text">Read more</a>
      </div>
    </div>
  )
}

class Test extends Component {
  state = {
    src: null,
    scannedData: null,
    generatedQR: null
  };

  openQRCamera = this.openQRCamera.bind(this);

  handleGenerate = str => {
    this.setState({ qrData: generateQR(str) });
  };

  componentDidMount() {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    const img = this.refs.image;
    const comp = this;

    img.onload = loaded => {
      const ratio = img.width / img.height;
      ctx.drawImage(img, 0, 0, height * ratio, height);
      const imgData = ctx.getImageData(0, 0, height * ratio, height);
      const code = jsQR(imgData.data, imgData.width, imgData.height);
      if (code) {
        const { data } = code;
        generateQR(data).then(qr =>
          comp.setState({ generatedQR: qr, scannedData: data })
        );
        console.log()
      } else {
        comp.setState({ scannedData: "Unable to find QR code. Try again." });
      }
    };
  }

  openQRCamera(node) {
    var reader = new FileReader();
    const comp = this;

    reader.onload = a => {
      // console.log(comp)
      comp.setState({ src: a.target.result });

      //const foo = convertDataURIToBinary(a.target.result);
      // var frameData = new Buffer(width * height * 4);

      //const code = jsQR(, 500, 500);
      //console.log(code)
      /*  
      node.value = "";
      QRCode.callback = function(res) {
        if(res instanceof Error) {
          alert("No QR code found. Please make sure the QR code is within the camera's frame and try again.");
        } else {
          alert(res);
        }
      };
      QRCode.decode(a.target.result);
      */
    };
    if (node.target.files[0]) {
      reader.readAsDataURL(node.target.files[0]);
    }
  }

  render() {
    const { scannedData, generatedQR } = this.state;
    return (
      <div>
        <canvas
          ref={"canvas"}
          width={width}
          height={height}
          style={{ display: "none" }}
        />
        <img ref="image" src={this.state.src} style={{ display: "none" }} alt='' />
        <br />
        <label style={styles.button} className="uk-button uk-button-primary">
          Scan QR
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={this.openQRCamera}
            tabIndex="-1"
            style={styles.input}
          />
        </label>
        <Link to='/create' className="uk-button uk-button-secondary uk-margin-left">
          Generate QR
        </Link>
        <p>{this.state.result}</p>
        {scannedData && (
          <div className='uk-card'>
            <Item data={scannedData} qrData={generatedQR} />
          </div>
        )}
      </div>
    );
  }
}

export default Test;
