import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser, forgotPassword } from "../actions";

const styles = {
    wrapper: {
        maxWidth: 500,
        margin: '0 auto',
        paddingTop: '2rem'
    }
}

class Login extends Component {
    state = {
        email: "",
        password: "",
        showForgot: false
    };

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { dispatch } = this.props;
        const { email, password } = this.state;

        dispatch(loginUser(email, password));
    };

    handleForgot = e => {
        e.preventDefault();
        const { dispatch } = this.props;
        const { email } = this.state;
        if (!email) return null;
        // console.log('ssene:', email)
        dispatch(forgotPassword(email))
    }

    clearForm = () => {
        this.setState({ showForgot: false })
    }

    render() {
        const {
            loginError,
            isAuthenticated,
            requestPasswordError,
            requestPasswordSent,
            location,
        } = this.props;

        const { showForgot } = this.state;
        const redirectTo = location?.state?.from;
        const cameFrom = redirectTo || '/';

        if (isAuthenticated) {
            return <Redirect to={cameFrom} />;
        } else {
            return (
                <div style={styles.wrapper}>
                    {redirectTo &&
                        <div className="uk-alert-warning" data-uk-alert>
                            <a className="uk-alert-close" data-uk-close></a>
                            <p>Please sign in to access that page</p>
                        </div>
                    }
                    <form className='uk-padding'>
                        <div className="uk-margin">
                            <div className="uk-width-1-1 uk-inline">
                                <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: user"></span>
                                <input
                                    className="uk-input uk-width-1-1"
                                    type="text"
                                    name='email'
                                    placeholder='Email'
                                    onChange={this.handleEmailChange}
                                />
                            </div>
                        </div>
                        {!showForgot &&
                            <Fragment>
                                <div className="uk-margin">
                                    <div className="uk-width-1-1 uk-inline">
                                        <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
                                        <input
                                            className="uk-input"
                                            type="password"
                                            name='password'
                                            placeholder='Password'
                                            onChange={this.handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <button className='uk-button uk-button-primary' onClick={this.handleSubmit}>Log in</button>
                            </Fragment>
                        }
                        <div className="uk-margin">
                            {showForgot && <button className='uk-button uk-button-secondary uk-margin-right' onClick={() => this.clearForm()}>Back</button>}
                            {showForgot && <button className='uk-button uk-button-primary' onClick={this.handleForgot}>Send me a new password</button>}
                            {!showForgot && <a href='#' onClick={() => this.setState({ showForgot: !showForgot })}>Forgot password?</a>}
                        </div>
                        {requestPasswordSent &&
                            <div className="uk-alert-success" data-uk-alert>
                                <p>Password reset link sent to <em>{this.state.email}</em>.</p>
                            </div>
                        }
                        {loginError && (
                            <div className="uk-alert-danger" data-uk-alert>
                                <p>Incorrect credentials.</p>
                            </div>
                        )}
                        {requestPasswordError &&
                            <div className="uk-alert-danger" data-uk-alert>
                                <p>{requestPasswordError.message}</p>
                            </div>
                        }
                    </form>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
        requestPasswordSent: state.auth.requestPasswordSent,
        requestPasswordError: state.auth.requestPasswordError
    };
}

export default connect(mapStateToProps)(Login);