import {
    db
} from "../lib/firebase/firebase";

// import {todosRef} from '../firebase'

export const FETCH_PLINGS = 'FETCH_PLINGS';
export const FETCH_PLING = 'FETCH_PLING';

export const addPling = newPling => async dispatch => {
    db.collection("plings").add(newPling);
};
export const savePling = (plingId, newPling) => async dispatch => {
    console.log(plingId, newPling)
    db.collection("plings").doc(plingId).set(newPling);
};
export const fetchPlings = () => async dispatch => {
    db
        .collection("plings")
        .onSnapshot(querySnapshot => {
            let res = {};
            querySnapshot.forEach(doc => {
                res[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_PLINGS,
                payload: res
            });
        });
};

export const fetchPling = plingId => async dispatch => {
    db
        .collection("plings")
        .doc(plingId)
        .onSnapshot(querySnapshot => {
            let res = querySnapshot.data();
            dispatch({
                type: FETCH_PLING,
                payload: {
                    [querySnapshot.id]: res
                }
            });
        });
};