import React, { Fragment } from 'react';
import { connect } from "react-redux";

import { Route, Switch } from "react-router-dom";

import NotFound from './pages/404';
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./components/Home";
import Login from "./components/Login";
import Pling from "./components/Pling";
import All from './components/All';
import Create from './components/Create';

// import logo from './logo.svg';
import './App.css';
// import qrcode from 'qrcode';

import TopNav from './components/TopNav';
import Sidebar from './containers/Sidebar';
import { useMediaQuery } from './hooks/useMediaQuery';

const Plings = () => {
  return 'PLINGS view';
}

const styles = {
  aside: {
    backgroundColor: '#222',
    position: 'fixed',
    left: '0',
    top: '80px',
    bottom: '0',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '260px',
    zIndex: '1',
  },
  main: isRowBased => ({
    marginLeft: isRowBased ? '260px' : 0,
  })
}

function App(props) {
  const { isAuthenticated, isVerifying } = props;
  const isRowBased = useMediaQuery('(min-width: 960px)');

  return (
    <Fragment>
      <div style={{
      }}>
        <TopNav isAuthenticated={isAuthenticated} />
        <aside id="left-col" className="uk-light uk-visible@m" style={styles.aside}>
          <Sidebar />
        </aside>
        <div style={styles.main(isRowBased)}>
          <Switch>
            <ProtectedRoute
              exact
              path="/plings"
              component={Plings}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path="/create"
              component={Create}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <Route path="/login" component={Login} />
            <Route path="/all" component={All} />
            <Route path="/pling/:plingId" component={Pling} />
            <Route exact path="/" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </div>

      </div>
    </Fragment >
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(App);
