import React, { useState, useEffect } from 'react'
import { Field, reduxForm, formValues } from 'redux-form'
import { connect } from 'react-redux';

import { generateQR } from '../Qr';
import { Link } from 'react-router-dom';
import { fetchPling, savePling } from '../actions/pling';

const fields = {

    title: {
        name: "title",
        component: "input",
        type: "text",
        className: "uk-input uk-form-large",
        placeholder: 'Title',
        // label: 'Title',
        // icon: 'bold'
    },
    description: {
        name: "description",
        component: "textarea",
        type: "textarea",
        className: "uk-textarea",
        placeholder: 'description',
        rows: 4,
        //label: 'description',
        // icon: 'quote-right'
    },
    email: {
        name: "email",
        component: "input",
        type: "text",
        className: "uk-input",
        placeholder: 'Email',
        label: 'Email',
        icon: 'mail'
    },
};
/*
{
    name: "description",
    component: "textarea",
    type: "textarea",
    className: "uk-input",
    placeholder: 'description',
    rows: 4,
    //label: 'description',
    icon: 'quote-right'
},
{
    name: "email",
    component: "input",
    type: "text",
    className: "uk-input",
    placeholder: 'Email',
    label: 'Email',
    icon: 'mail'
},
{
    name: "name",
    component: "input",
    type: "text",
    className: "uk-input",
    placeholder: 'Name',
    label: 'Name',
    icon: 'user'
},
{
    name: "phone",
    component: "input",
    type: "tel",
    className: "uk-input",
    placeholder: 'Phone#',
    label: 'Phone#',
    icon: 'phone'
},
{
    name: "reminder",
    component: "input",
    type: "datetime-local",
    className: "uk-input",
    placeholder: 'Reminder',
    label: 'Reminder',
    icon: 'bell'
}
*/


function FirebaseField({ children }) {
    /*
    const onClick = () => {
        console.log('this')
    };
    let a = {...children};
    a.onClick = onClick;
    console.log(children)
    */
    return (
        <div>
            <span className="uk-form-icon" uk-icon={`icon: bell`}></span>
            {children}
        </div>
    );
}

function PlingNav({ children, secondary }) {
    return (
        <nav class="uk-navbar-container" data-uk-navbar>
            <div class="uk-navbar-left">
                {children}
            </div>
            {secondary &&
                <div class="uk-navbar-right">
                    {secondary}
                </div>
            }
        </nav>
    )
}

function InputField({ fields }) {
    if (!fields) return null;
    return (
        <div className="uk-margin">
            <label className="uk-form-label" htmlFor={fields.name}>{fields.label}</label>
            <div className="uk-form-controls">
                <div className="">
                    {fields.icon &&
                        <span className="uk-form-icon uk-form-icon-flip uk-margin-right" uk-icon={`icon: ${fields.icon}`}></span>
                    }
                    <Field
                        name={fields.name}
                        component={fields.component}
                        type={fields.type}
                        className={fields.className}
                        placeholder={fields.placeholder}
                        rows={fields.rows}
                    />
                </div>
            </div>
        </div>
    );
}

function Pling({
    dispatch,
    match,
    pling = {},
    dirty,
    formFields,
    submitting,
    submitSucceeded,
    initialValues,
    handleSubmit,
    initialize,
    user
}) {
    const { plingId: id } = match.params;
    const [qrSrc, setQr] = useState(false)

    const handleSubmittt = values => {
        console.log(values)
        //e.preventDefault();
        values.uid = user.uid;
        values.edited = new Date().getTime();
        if (formFields) {
            dispatch(savePling(id, values));
            dispatch(initialize(values))
            //console.log(formFields)
        }
    }

    useEffect(() => {
        dispatch(fetchPling(id));
        generateQR(id).then(a => setQr(a));
        return () => {
            // cleanup();
        };
    }, [])

    console.log(pling)

    return (
        <div>
            <PlingNav
                secondary={qrSrc &&
                    <img src={qrSrc} alt={id} style={{ width: '100px' }} />
                }
            >
                <ul className="uk-breadcrumb uk-margin-left">
                    <li>{id}</li>
                </ul>
            </PlingNav>
            <form onSubmit={handleSubmit(handleSubmittt)} className='uk-padding'>
                <button className='uk-button uk-button-primary' type='submit' disabled={!dirty}>Save changes</button>
                <div className="uk-child-width-expand uk-margin" data-uk-grid>
                    <div>

                        {

                            //     
                            /**
                            fields.map(field => (
                                <div className="uk-margin uk-child-width-auto">
                                    <label className="uk-form-label" htmlFor={field.name}>{field.label}</label>
                                    <div className="uk-form-controls uk-child-width-auto">
                                        <div className="uk-inline uk-child-width-auto">
                                            {field.icon &&
                                                <span className="uk-form-icon uk-form-icon-flip uk-margin-right" uk-icon={`icon: ${field.icon}`}></span>
                                            }
                                            <Field
                                                name={field.name}
                                                component={field.component}
                                                type={field.type}
                                                className={field.className}
                                                placeholder={field.placeholder}
                                            />
                                        </div>
                                    </div>
    
                                </div>
                            ))
                            */
                        }
                    </div>
                </div>

                <InputField fields={fields.title} />
                <InputField fields={fields.description} />

                <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m">
                    <h3 className="uk-card-title">Meta</h3>
                    {Object.keys(pling).filter(k => k !== 'title' && k !== 'description').map(k => {
                        let val = pling[k];
                        const value = typeof pling[k] === 'string' && pling[k]

                        if (typeof pling[k] === 'object') {
                            // pling[k]
                            console.log('is object: ', pling[k])
                            //val = pling[k].map(a => '.');
                        }
                        return value ? <p key={k}>{k}: {value}</p> : null;
                    })}
                </div>

            </form>
        </div>
    );
}

// export default Pling;
let InitializeFromStateForm;

InitializeFromStateForm = reduxForm({
    form: 'edit' // a unique identifier for this form
})(Pling)

// You have to connect() to any reducers that you wish to connect to yourself
InitializeFromStateForm = connect(
    (state, props) => ({
        initialValues: state.plings[props.match.params.plingId], // pull initial values from account reducer
        formFields: (state.form.edit || {}).values,
        pling: state.plings[props.match.params.plingId],
        user: state.auth.user
    }),
    // {load: loadAccount } // bind account loading action creator
)(InitializeFromStateForm)

export default InitializeFromStateForm;
        /*
export default reduxForm({
// a unique name for the form
form: 'edit',
initialValues: {
title: 'kaka',
},
})(connect(
(state, props) => ({
//initialValues: state.plings[props.match.params.plingId], // pull initial values from account reducer

values: (state.form.create || {}).values,
pling: state.plings[props.match.params.plingId]
}),
)(Pling))
*/