import React, { Fragment } from 'react'
import QrReader from '../QrReader';
import All from './All';

export default () => {
    return (
        <Fragment>
            <div className="">
                <div className="uk-background-primary uk-light uk-padding"
                    style={{
                        background: 'linear-gradient(90deg, rgba(4,65,110,1) 0%, rgba(0,170,255,1) 100%)'
                    }}
                >
                    <h2>Google keep track of everything on the internet<br />
                        - Plings keep track of the rest.</h2>
                    <h4>
                        A Universal Decentralized Ecosystem for Object Addressing providing Value To Physical Objects to Save the world's resources.
                    </h4>
                    <QrReader />
                </div>
            </div>
        </Fragment>
    )
}