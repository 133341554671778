import React from 'react';
import { logoutUser } from "../actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const styles = {
    aside: {
        /*
        backgroundColor: '#222',
        position: 'fixed',
        left: '0',
        top: '0',
        bottom: '0',
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '260px',
        zIndex: '1',
        */
    }
}
/*
function UserNav({ email }) {
    return (
        <div>
            u: {email}
        </div>
    )
}
*/
// <UserNav {...user} />

function Sidebar({ dispatch, user, isAuthenticated }) {
    console.log(isAuthenticated)
    return (
        <aside id="left-col" className="uk-light" style={styles.aside}>
            <div className="bar-wrap">
                <ul className="uk-nav" data-uk-nav style={{
                    padding: '2rem'
                }}>
                    <li><Link to="/all"><span className="uk-margin-small-right" uk-icon="icon: thumbnails"></span> Public</Link></li>
                    <li className="uk-nav-divider"><hr /></li>
                    {isAuthenticated &&
                        <li><Link to="/" onClick={() => dispatch(logoutUser())}><span className="uk-margin-small-right" uk-icon="icon: sign-out"></span> Sign out</Link></li>
                    }
                    {!isAuthenticated &&
                        <li><Link to="/login"><span className="uk-margin-small-right" uk-icon="icon: sign-in"></span> Sign in</Link></li>
                    }
                </ul>
            </div>
        </aside>
    )
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(Sidebar);