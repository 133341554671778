import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBG2ypiQg0pf76fFSpO4CQCXeZ8KNwNTBE",
    authDomain: "plings.firebaseapp.com",
    databaseURL: "https://plings.firebaseio.com",
    projectId: "plings",
    storageBucket: "plings.appspot.com",
    messagingSenderId: "534556399010",
    appId: "1:534556399010:web:42153539001db562ea0efb",
    measurementId: "G-HDDMJRPFCK"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;