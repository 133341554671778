import {
  FETCH_PLINGS,
  FETCH_PLING
} from '../actions/pling';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PLINGS:
      return action.payload;
    case FETCH_PLING:
      return action.payload;
    default:
      return state;
  }
};