import QRCode from "qrcode";

const site = 'https://plings.io'

const generateQR = async text => {
  try {
    return await QRCode.toDataURL(site + '/?pling=' + text, {
      width: 160,
    });
  } catch (err) {
    console.error(err);
  }
};

export {
  generateQR
};