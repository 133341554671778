import React, { Fragment } from 'react'

import Sidebar from '../containers/Sidebar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from "../actions";

const TopNav = ({ dispatch, user, isAuthenticated, isLoggingIn }) => {
    return (
        <Fragment>
            <nav className="uk-navbar-container" data-uk-navbar data-uk-sticky>
                <div className="uk-navbar-left">
                    <ul className="uk-navbar-nav uk-margin-left">
                        <li>
                            <Link to="/">
                                <span uk-icon="icon: home"></span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/"><strong>PLINGS</strong></Link>
                        </li>
                    </ul>
                </div>
                <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav uk-margin-right">
                        {!isAuthenticated && !isLoggingIn &&
                            <li>
                                <Link to="/login" className="uk-button"><span className="uk-margin-small-right" uk-icon="icon: sign-in"></span> Sign in</Link>
                            </li>
                        }
                        {user?.email && (
                            <div className="uk-visible@s">
                                <span className="uk-margin-small-right" uk-icon="icon: user"></span>
                                <a title={user.email}>{user.email.split('@')[0]}</a>
                            </div>
                        )}
                        <li className="uk-hidden@m">
                            <button className="uk-button" uk-toggle="target: #my-id" style={{ height: '100%' }}>
                                <span uk-icon="icon: menu"></span>
                            </button>
                        </li>
                    </ul>

                </div>
            </nav>
            <div id="my-id" data-uk-offcanvas="flip: true;">
                <div className="uk-offcanvas-bar">
                    <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                    <Sidebar />
                </div>
            </div>
        </Fragment>
    )
}

function mstp(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        user: state.auth.user,
    };
}

export default connect(mstp)(TopNav);