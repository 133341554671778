import React, { Fragment, useEffect, useState } from 'react'
import { Field, reduxForm, formValues } from 'redux-form'
import { connect } from 'react-redux';
import { addPling } from '../actions/pling';

// If we have a successful location update
function onGeoSuccess(event) {
    // document.getElementById("lat").value = event.coords.latitude;
    // document.getElementById("long").value = event.coords.longitude;

}

// If something has gone wrong with the geolocation request
function onGeoError(event) {
    alert(event.message);
}

function getLocationConstant() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
    } else {
        alert("Your browser or device doesn't support Geolocation");
    }
}

const fields = [
    {
        name: "title",
        component: "input",
        type: "text",
        className: "uk-input uk-form-large",
        placeholder: 'Title',
        //label: 'Title',
        icon: 'bold'
    },
    {
        name: "description",
        component: "textarea",
        type: "textarea",
        className: "uk-input",
        placeholder: 'description',
        //label: 'description',
        icon: 'quote-right'
    },
    {
        name: "email",
        component: "input",
        type: "text",
        className: "uk-input",
        placeholder: 'Email',
        label: 'Email',
        icon: 'mail'
    },
    {
        name: "name",
        component: "input",
        type: "text",
        className: "uk-input",
        placeholder: 'Name',
        label: 'Name',
        icon: 'user'
    },
    {
        name: "phone",
        component: "input",
        type: "tel",
        className: "uk-input",
        placeholder: 'Phone#',
        label: 'Phone#',
        icon: 'phone'
    },
    {
        name: "reminder",
        component: "input",
        type: "datetime-local",
        className: "uk-input",
        placeholder: 'Reminder',
        label: 'Reminder',
        icon: 'bell'
    }
]

function Create(props) {
    const [geo, setGeoState] = useState(false)

    useEffect(() => {
        // getLocationConstant()
        return () => {

        };
    }, [])

    const setGeo = e => {
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(event => {
            const { latitude, longitude } = event.coords;
            setGeoState({ latitude, longitude })
            document.getElementById("geo").value = `${latitude},${longitude}`;
        }, onGeoError);
    }

    const handleSubmit = values => {
        //e.preventDefault();
        props.dispatch(addPling(values))
    }

    console.log(props)

    return (
        <Fragment>
            <div className="uk-padding">
                <h1>Create pling</h1>
                <div className="">
                    <form onSubmit={props.handleSubmit(handleSubmit)}>
                        {[fields[0], fields[1]].map(field => (
                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text">{field.label}</label>
                                <div className="uk-form-controls">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon uk-form-icon-flip" uk-icon={`icon: ${field.icon}`}></span>
                                        <Field
                                            name={field.name}
                                            component={field.component}
                                            type={field.type}
                                            className={field.className}
                                            placeholder={field.placeholder}
                                        />
                                    </div>
                                </div>

                            </div>
                        ))}

                        <ul uk-accordion="multiple: true">
                            <li>
                                <a className="uk-accordion-title" href="#"><span uk-icon="icon: user"></span> Contact</a>
                                <div className="uk-accordion-content">
                                    {fields.splice(2, 10).map(field => (
                                        <div className="uk-margin">
                                            <label className="uk-form-label" for="form-stacked-text">{field.label}</label>
                                            <div className="uk-form-controls">
                                                <div className="uk-inline uk-width-1-1">
                                                    <span className="uk-form-icon uk-form-icon-flip" uk-icon={`icon: ${field.icon}`}></span>
                                                    <Field
                                                        name={field.name}
                                                        component={field.component}
                                                        type={field.type}
                                                        className={field.className}
                                                        placeholder={field.placeholder}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#"><span uk-icon="icon: location"></span> Location</a>
                                <div className="uk-accordion-content">
                                    <div className="uk-margin">
                                        <div className="uk-inline">
                                            <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: location"></span>
                                            <Field
                                                className="uk-input"
                                                type="text"
                                                name='geo'
                                                placeholder='Location'
                                                id='geo'
                                                component='input'
                                            />
                                        </div>
                                        <div className="uk-inline uk-margin-left">
                                            <button className="uk-button uk-button-primary" onClick={e => setGeo(e)}>
                                                Current location
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <div className="uk-margin">
                            <button className='uk-button uk-button-primary' type='submit' disabled={!props.dirty || props.submitSucceeded}>Generate</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default reduxForm({
    // a unique name for the form
    form: 'create'
})(connect(
    state => ({
        // initialValues: state.account.data // pull initial values from account reducer
        values: (state.form.create || {}).values
    }),
)(Create))