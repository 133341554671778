import React, { Fragment, useEffect, useState } from 'react';
import { fetchPlings } from '../actions/pling';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { generateQR } from '../Qr';
//import { db } from '../lib/firebase/firebase'
//import * as d3 from "d3";
//import data from '../flare-2.json'

//const width = 975;
//const height = width;

/*
const pack = data => d3.pack()
    .size([width - 2, height - 2])
    .padding(3)
    (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value))

const chart = () => {
    const root = pack(data);

    const svg = d3.create("svg")
        .attr("viewBox", [0, 0, width, height])
        .style("font", "10px sans-serif")
        .attr("text-anchor", "middle");

    const shadow = DOM.uid("shadow");

    svg.append("filter")
        .attr("id", shadow.id)
        .append("feDropShadow")
        .attr("flood-opacity", 0.3)
        .attr("dx", 0)
        .attr("dy", 1);

    const node = svg.selectAll("g")
        .data(d3.nest().key(d => d.height).entries(root.descendants()))
        .join("g")
        .attr("filter", shadow)
        .selectAll("g")
        .data(d => d.values)
        .join("g")
        .attr("transform", d => `translate(${d.x + 1},${d.y + 1})`);

    node.append("circle")
        .attr("r", d => d.r)
        .attr("fill", d => color(d.height));

    const leaf = node.filter(d => !d.children);

    leaf.select("circle")
        .attr("id", d => (d.leafUid = DOM.uid("leaf")).id);

    leaf.append("clipPath")
        .attr("id", d => (d.clipUid = DOM.uid("clip")).id)
        .append("use")
        .attr("xlink:href", d => d.leafUid.href);

    leaf.append("text")
        .attr("clip-path", d => d.clipUid)
        .selectAll("tspan")
        .data(d => d.data.name.split(/(?=[A-Z][a-z])|\s+/g))
        .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
        .text(d => d);

    node.append("title")
        .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);

    return svg.node();
}
*/
function Pling({
    description,
    contact,
    geo,
    phone,
    title,
    id
}) {
    const [qrSrc, setstate] = useState(false)
    generateQR(id).then(a => setstate(a));

    return (
        <div className="uk-margin-bottom">
            <div className="uk-grid-collapse" data-uk-grid>
                <div>
                    <div className="">
                        <div className="uk-card-title">
                            <Link to={`/pling/${id}`}>{title}</Link>
                        </div>
                        {geo}
                        {contact}
                        {phone}
                        {description}
                    </div>

                </div>
            </div>
        </div>
    )
}

function PlingMap() {
    const [d3, setd3] = useState();
    useEffect(() => {
        setd3();
        return () => {
            // cleanup
        };
    }, [])
    return <div data={d3} />;
}

function All({ dispatch, plings }) {

    const cleanup = () => {
        console.log('clean')
    };

    useEffect(() => {
        dispatch(fetchPlings());
        return () => {
            cleanup();
        };
    }, [])

    return (
        <Fragment>
            <div className='uk-padding'>
                <PlingMap />
                <h2 className="uk-heading-line"><span>All plings</span></h2>
                {Object.keys(plings).map((pling, i) =>
                    <Pling key={i} id={pling} {...plings[pling]} />
                )}
            </div>


        </Fragment>
    )
}

export default connect(({ plings }) => ({ plings }))(All);